<template>
  <div id="app">
    <login-page v-if="!token" />
    <form-page v-if="token" />
  </div>
</template>

<script>
import LoginPage from "./pages/login";
import FormPage from "./pages/form";

export default {
  name: "App",

  components: {
    LoginPage,
    FormPage,
  },

  data() {
    return { token: null };
  },

  provide() {
    return {
      getToken: this.getToken,
      setToken: this.setToken,
    };
  },

  methods: {
    setToken(token) {
      this.token = token;
    },

    getToken() {
      return this.token;
    },
  },
};
</script>

<style>
#app {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
