import axios from 'axios';

export const login = ({ email, password }) => {
  return axios({
    method: 'post',
    url: '/api/login/',
    data: { email, password },
  });
};

export const sendIcfUrl = ({ email, tel }, token) => {
  return axios({
    method: 'post',
    url: '/api/send/',
    data: { email, phone: tel },
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
