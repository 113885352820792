<template>
  <div>
    <validation-provider
      :name="name"
      :rules="rules"
      v-slot="{ errors, touched, pristine }"
    >
      <div
        class="form__field-wrapper"
        :class="{ error: (touched || pristine) && errors[0] }"
      >
        <slot name="label">
          <h3 class="form__field-label">Email Address</h3>
        </slot>

        <v-input
          v-model="email"
          :placeholder="placeholder"
          type="email"
          class="form__field"
          autocomplete="email"
        />

        <slot name="error">
          <div class="error-message form__field-error" v-if="errors[0]">
            {{ errors[0] }}
          </div>
        </slot>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import vInput from "@/components/input";

export default {
  components: { vInput },

  props: {
    value: { type: String, default: "" },
    placeholder: { type: String, default: "Enter your email address" },
    name: { type: String, default: "Email" },
    rules: { type: String, default: "required|email" },
  },

  computed: {
    email: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
