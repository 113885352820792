<template>
  <div>
    <validation-provider
      :name="name"
      rules="required|min:8|max:60"
      v-slot="{ errors, touched, pristine }"
    >
      <div
        class="form__field-wrapper"
        :class="{ error: (touched || pristine) && errors[0] }"
      >
        <slot name="label">
          <h3 class="form__field-label">Password</h3>
        </slot>

        <v-input
          v-model="password"
          :placeholder="placeholder"
          type="password"
          class="form__field"
          autocomplete="password"
        />

        <slot name="error">
          <div class="error-message form__field-error" v-if="errors[0]">
            {{ name }} should be between 8 and 60 characters
          </div>
        </slot>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import vInput from "@/components/input";

export default {
  components: { vInput },

  props: {
    value: { type: [String, Number], default: "" },
    placeholder: { type: String, default: "" },
    name: { type: String, default: "Password" },
  },

  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
