<template>
  <div class="form">
    <slot v-if="$slots.title" name="title" />
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-email v-model="email" rules="email" />
        <v-phone v-model="tel" />
        <div class="form__errors" v-if="$slots.error">
          <slot name="error" />
        </div>
        <div class="form__success" v-if="$slots.success">
          <slot name="success" />
        </div>
        <div class="form__submit-wrapper">
          <button
            type="submit"
            class="button button_secondary form__submit-button"
            :disabled="loading"
          >
            <slot name="button" />
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import vEmail from "./formElements/email";
import vPhone from "./formElements/phone";

export default {
  components: { vEmail, vPhone },

  props: {
    loading: { type: Boolean, default: false },
  },

  data: () => ({
    tel: "",
    email: "",
  }),

  methods: {
    onSubmit() {
      const { email, tel } = this;
      return this.$emit("on-submit", { email, tel });
    },
  },
};
</script>
