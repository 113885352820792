<template>
  <div class="login">
    <div class="container">
      <card>
        <h2 class="login__header-title" slot="header">
          Send URL by Email or SMS
        </h2>
        <send-form :loading="loading" @on-submit="onSubmit" ref="form">
          <template v-slot:button>send</template>
          <template v-slot:error v-if="error">
            <span class="sign-in">{{ error || "Cannot send ICF URL" }}</span>
          </template>
          <template v-slot:success v-if="success">
            <span class="sign-in">URL has been successfully sent.</span>
          </template>
        </send-form>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/card";
import { formatErrorsToString } from "@/modules/utils";
import SendForm from "@/components/sendIcfForm";
import { sendIcfUrl } from "@/api";

export default {
  name: "FormPage",

  components: { SendForm, Card },

  data() {
    return {
      loading: false,
      success: false,
      error: null,
    };
  },

  inject: ["getToken"],

  methods: {
    onSubmit(payload) {
      this.error = null;
      this.success = false;

      if (!payload.tel && !payload.email) {
        this.error = "Email or Phone Number should be filled";

        return;
      }

      this.loading = true;

      return this.send(payload)
        .then(this.successHandler)
        .catch(this.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    send(payload) {
      return sendIcfUrl(payload, this.getToken());
    },

    successHandler() {
      this.success = true;
    },

    errorHandler(e) {
      if (e?.response?.data) {
        this.error = formatErrorsToString(e.response.data);
      }
    },
  },
};
</script>
