/**
 * @param {errors<Object>}
 * @return {<String>}
 */
export function formatErrorsToString(errors = {}) {
  if (typeof errors === 'string') return errors;
  if (typeof errors !== 'object' || !Object.keys(errors).length)
    return 'Something wrong, please try again some minutes later';

  return Object.entries(errors).reduce((acc, [key, arr]) => {
    let errorsJoined;
    if (Array.isArray(arr)) errorsJoined = arr.join(', ');
    else if (typeof arr === 'string') errorsJoined = arr;
    else errorsJoined = JSON.stringify(arr);
    return `${acc}<span> ${key}: ${errorsJoined}</span>`;
  }, '');
}
