import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { UI_PHONE_MASK } from '@/constants';

import { required, email, min, max } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Field {_field_} is required',
});

extend('email', email);
extend('min', min);
extend('max', max);

extend('phone', {
  validate: (value) => ({
    valid:
      value.length === 0 ||
      (Array.from(value).every((char, i) => {
        const mask = UI_PHONE_MASK[i];
        if (typeof mask === 'string') {
          return char === mask;
        }

        if (mask instanceof RegExp) {
          return mask.test(char);
        }

        return false;
      }) &&
        value.length === UI_PHONE_MASK.length),
  }),
  message: 'Invalid phone number',
});

export { ValidationProvider, ValidationObserver };
