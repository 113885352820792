<template>
  <div>
    <validation-provider
      name="phone"
      rules="phone"
      v-slot="{ errors, touched, pristine }"
    >
      <div
        class="form__field-wrapper"
        :class="{ error: (touched || pristine) && errors[0] }"
      >
        <h3 class="form__field-label">Phone Number</h3>
        <div class="input">
          <masked-input
            type="text"
            name="phone"
            v-model="phoneValue"
            class="form__field input__node"
            :mask="mask"
            :guide="false"
            autocomplete="userphone"
          />
          <span class="input__placeholder">(___) ___-____</span>
        </div>
        <div class="error-message form__field-error" v-if="errors[0]">
          {{ errors[0] }}
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";
import { UI_PHONE_MASK } from "@/constants";

export default {
  components: { MaskedInput },

  props: {
    value: { type: String, default: "" },
  },

  computed: {
    mask() {
      return UI_PHONE_MASK;
    },
    phoneValue: {
      get() {
        return this.value;
      },
      set(phone) {
        this.$emit("input", phone);
      },
    },
  },
};
</script>
