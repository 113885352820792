<template>
  <div class="login">
    <div class="container">
      <card>
        <h2 class="login__header-title" slot="header">Sign in</h2>
        <auth-form
          :loading="loading"
          @on-submit="onSubmit"
          ref="form"
          pass-placeholder="Enter your password"
        >
          <template v-slot:button>sign in</template>
          <template v-slot:error v-if="error">
            <span class="sign-in"> Incorrect email or password. </span>
          </template>
        </auth-form>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/card";
import { formatErrorsToString } from "@/modules/utils";
import AuthForm from "@/components/authForm";
import { login } from "@/api";

export default {
  name: "LoginPage",

  components: { AuthForm, Card },

  data() {
    return {
      loading: false,
      error: null,
    };
  },

  inject: ["setToken"],

  methods: {
    onSubmit(payload) {
      this.error = null;
      this.loading = true;

      return this.authorization(payload)
        .then(this.redirectHandler)
        .catch(this.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    authorization(payload) {
      return login(payload);
    },

    redirectHandler({ data: { token } }) {
      this.setToken(token);
    },

    errorHandler(e) {
      if (e?.response?.data) {
        this.error = formatErrorsToString(e.response.data);
      }
    },
  },
};
</script>
